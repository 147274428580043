import {global} from './global'
import {store} from '@/store'

let _baseurl = global.baseApiUrl + 'factura/'

export default {
  cargaFactura (data) {
    return global.axios.post(_baseurl + 'carga-factura', data)
  },
  getFactura () {
    return global.axios.get(_baseurl + 'get-factura?id=' + store.getters.user.asociado.canalAsociadoId)
  },
  // getLastFacturaDisponibles () {
  //   return global.axios.get(_baseurl + 'get-last-factura-disponible?id=' + store.getters.user.asociado.id)
  // },
  getNotasCreditos () {
    return global.axios.get(_baseurl + 'get-notas-credito?id=' + store.getters.user.asociado.canalAsociadoId)
  },
  DescargaFacturaNotaCredito (id, tipo) {
    return global.axios.get(_baseurl + 'descarga-factura-nota-credito?id=' + id + '&tipo=' + tipo, {responseType: 'blob', headers: {'Accept': 'application/octet-stream', 'Content-Type': 'application/json'}})
  },
  getNCFMask () {
    return global.axios.get(_baseurl + 'get-ncf-mask')
  },
  validarNCFFacturaNotaCredito (ncf) {
    return global.axios.get(_baseurl + 'validar-ncf-factura-nota-credito?canalasociadoid=' + store.getters.user.asociado.canalAsociadoId + '&ncf=' + ncf)
  }
}
