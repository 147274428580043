<template>
  <div>
    <v-card-text>
      <v-card outlined class="white pa-2">
        <v-tabs>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab href="#tab1">Facturas</v-tab>
          <v-tab href="#tab2">Notas de Creditos</v-tab>
          <v-tab-item value="tab1">
            <br />
            <v-data-table
              item-key="Id"
              :headers="headers"
              :items="filteredFacturas"
              :loading="progressFactura"
              :items-per-page="5"
            >
              <template v-slot:item.Bloqueada="{ item }">
                <v-tooltip v-if="item.Bloqueada" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">lock</v-icon></template
                  >
                  <span>Factura bloqueada por entrega tardía</span>
                  <br />
                  <span v-if="!item.FacturaCargada"
                    >Falta subir Nota de Credito</span
                  >
                </v-tooltip>
              </template>

              <template v-slot:item.FechaProcesado="{ item }">{{
                item.FechaProcesado | date
              }}</template>

              <template v-slot:item.FechaRecibido="{ item }">{{
                item.FechaRecibido | date
              }}</template>

              <template v-slot:item.HoraRecibido="{ item }">{{
                item.FechaRecibido | hour
              }}</template>

              <template v-slot:item.EstadoCode="{ item }">
                <v-chip label v-if="item.EstadoCode === 'A'" color="info"
                  >Aprobado</v-chip
                >
                <v-chip label v-if="item.EstadoCode === 'D'" color="red"
                  >Devuelto</v-chip
                >
                <v-chip label v-if="item.EstadoCode === 'P'" color="#EB672D"
                  >Pendiente</v-chip
                >
                <v-chip label v-if="item.EstadoCode === 'R'" color="secondary"
                  >Recibido</v-chip
                >
              </template>
              <template v-slot:item.action="{ item }">
                <v-menu offset-y dark>
                  <template v-slot:activator="{ on }">
                    <v-btn text v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list nav>
                    <v-list-item
                      v-if="
                        item.EstadoCode !== 'P' && item.ImagenFacturaCargada
                      "
                      @click="download(item.Id, 'Factura')"
                    >
                      <v-list-item-title>
                        <v-icon left>download</v-icon>Descargar
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.EstadoCode === 'P' || item.EstadoCode === 'D'"
                      @click="upload(item, 'Factura')"
                    >
                      <v-list-item-title>
                        <v-icon left>publish</v-icon>Cargar
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.EstadoCode === 'P' || item.EstadoCode === 'D'"
                      @click="
                        dialog2 = true;
                        set(item);
                      "
                    >
                      <v-list-item-title>
                        <v-icon left>mdi-help-circle</v-icon>Generar
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table></v-tab-item
          >
          <v-tab-item value="tab2">
            <br />
            <v-data-table
              item-key="Id"
              :headers="headers"
              :items="filteredNotasCredito"
              :loading="progressNotaCredito"
              :items-per-page="5"
            >
              <template v-slot:item.Bloqueada="{ item }">
                <v-tooltip v-if="item.Bloqueada" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">lock</v-icon></template
                  >
                  <span>Factura bloqueada por entrega tardía</span>
                  <br />
                  <span v-if="!item.FacturaCargada"
                    >Falta subir Nota de Credito</span
                  >
                </v-tooltip>
              </template>
              <template v-slot:item.FechaProcesado="{ item }">{{
                item.FechaProcesado | date
              }}</template>

              <template v-slot:item.FechaRecibido="{ item }">{{
                item.FechaRecibido | date
              }}</template>

              <template v-slot:item.HoraRecibido="{ item }">{{
                item.FechaRecibido | hour
              }}</template>

              <template v-slot:item.EstadoCode="{ item }">
                <v-chip label v-if="item.EstadoCode === 'A'" color="info"
                  >Aprobado</v-chip
                >
                <v-chip label v-if="item.EstadoCode === 'D'" color="red"
                  >Devuelto</v-chip
                >
                <v-chip label v-if="item.EstadoCode === 'P'" color="#EB672D"
                  >Pendiente</v-chip
                >
                <v-chip label v-if="item.EstadoCode === 'R'" color="secondary"
                  >Recibido</v-chip
                >
              </template>
              <template v-slot:item.action="{ item }">
                <v-menu offset-y dark>
                  <template v-slot:activator="{ on }">
                    <v-btn text v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list nav>
                    <v-list-item
                      v-if="
                        item.EstadoCode !== 'P' && item.ImagenFacturaCargada
                      "
                      @click="download(item.Id, 'NotaCredito')"
                    >
                      <v-list-item-title>
                        <v-icon left>download</v-icon>Descargar
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.EstadoCode === 'P' || item.EstadoCode === 'D'"
                      @click="upload(item, 'Nota Credito')"
                    >
                      <v-list-item-title>
                        <v-icon left>publish</v-icon>Cargar
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.EstadoCode === 'P' || item.EstadoCode === 'D'"
                      @click="
                        dialog3 = true;
                        set(item);
                      "
                    >
                      <v-list-item-title>
                        <v-icon left>mdi-help-circle</v-icon>Generar
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />

        <v-radio-group v-model="selectedEstado">
          <template v-slot:label>
            <div>Estados</div>
          </template>
          <v-radio :value="null" key="todos">
            <template v-slot:label>
              <div>
                Todos
              </div>
            </template>
          </v-radio>
          <template v-for="(estado, index) in listEstados">
            <v-radio :value="estado.code" :key="index">
              <template v-slot:label>
                <div>
                  {{ estado.value }}
                </div>
              </template>
            </v-radio>
          </template>
        </v-radio-group>
      </v-card-text>
    </v-navigation-drawer>

    <v-dialog v-model="dialog1" persistent :max-width="maxDialogWidth">
      <v-card justify-center>
        <v-card-title>
          <span>Subir {{ item.tipo }}</span>
        </v-card-title>
        <v-form ref="form" lazy-validation v-if="dialog1">
          <div class="text-center">
            <div>
              Sociedad: <strong>{{ item.sociedad }}</strong>
            </div>
          </div>
          <v-card-text>
            <v-col cols="12">
              <v-text-field
                v-model="item.ncf"
                label="NCF"
                :rules="
                  item.tipo == 'Factura'
                    ? rules.facturasRCN
                    : rules.notasCreditoRCN
                "
                :maxlength="
                  item.tipo == 'Factura'
                    ? ncfFacturaMask.length === 0
                      ? undefined
                      : ncfFacturaMask.length
                    : ncfNotaCreditoMask.length === 0
                    ? undefined
                    : ncfNotaCreditoMask.length
                "
                :counter="
                  item.tipo == 'Factura'
                    ? ncfFacturaMask.length === 0
                      ? undefined
                      : ncfFacturaMask.length
                    : ncfNotaCreditoMask.length === 0
                    ? undefined
                    : ncfNotaCreditoMask.length
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-file-input
                label="Seleccionar archivo"
                v-model="item.file"
                accept="*"
                :show-size="1000"
                :rules="rules.fileRules"
                style="padding: 0px"
              ></v-file-input>
            </v-col>
            <v-subheader
              style="color: #ff5252; font-size: 12px; font-weight: 100"
              v-if="rules.ncfShowMessage"
              >{{ rules.ncfValidationMessage }}</v-subheader
            >
          </v-card-text>
          <v-card-actions>
            <v-btn
              depressed
              color="primary"
              :disabled="!formMasivaIsValid"
              @click="submit"
              >Guardar</v-btn
            >
            <v-btn depressed text color="error" @click.native="cancelInsert"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" persistent :max-width="maxDialogWidth">
      <v-card justify-center>
        <v-card-title>
          <span>Generar Factura</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formFacturaDescarga" v-if="dialog2">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.ncf"
                  :rules="rules.facturasRCN"
                  required
                  label="NCF"
                  :maxlength="
                    ncfFacturaMask.length === 0
                      ? undefined
                      : ncfFacturaMask.length
                  "
                  :counter="
                    ncfFacturaMask.length === 0
                      ? undefined
                      : ncfFacturaMask.length
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <date-selector
                  label="Fecha Emisión"
                  :fecha.sync="item.fechaEmision"
                  :rules="rules.fechaEmision"
                ></date-selector>
              </v-col>
              <v-col cols="12">
                <date-selector
                  label="Fecha Vencimiento"
                  :fecha.sync="item.fechaVencimiento"
                  :rules="rules.notNull"
                ></date-selector>
              </v-col>
            </v-row>
            <v-subheader
              style="color: #ff5252; font-size: 12px; font-weight: 100"
              v-if="rules.ncfShowMessage"
              >{{ rules.ncfValidationMessage }}</v-subheader
            >
          </v-form>
          <v-row row v-if="processing">
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            depressed
            color="primary"
            @click="generarFactura"
            :disabled="processing"
            >Generar</v-btn
          >
          <v-btn
            depressed
            text
            color="info"
            @click="generarVistaPreviaFactura"
            :disabled="processing"
            >Vista Previa</v-btn
          >
          <v-btn depressed text color="error" @click="cancelDownload"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" persistent :max-width="maxDialogWidth">
      <v-card justify-center>
        <v-card-title>
          <span>Generar Nota Credito</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formNotaCreditoDescarga" lazy-validation v-if="dialog3">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.ncf"
                  :rules="rules.notasCreditoRCN"
                  required
                  label="NCF"
                  :maxlength="
                    ncfNotaCreditoMask.length === 0
                      ? undefined
                      : ncfNotaCreditoMask.length
                  "
                  :counter="
                    ncfNotaCreditoMask.length === 0
                      ? undefined
                      : ncfNotaCreditoMask.length
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <date-selector
                  label="Fecha Emisión"
                  :fecha.sync="item.fechaEmision"
                  :rules="rules.fechaEmision"
                ></date-selector>
              </v-col>
            </v-row>
            <v-subheader
              style="color: #ff5252; font-size: 12px; font-weight: 100"
              v-if="rules.ncfShowMessage"
              >{{ rules.ncfValidationMessage }}</v-subheader
            >
          </v-form>
          <v-row row v-if="processing">
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            depressed
            color="primary"
            @click="generarNotaCredito"
            :disabled="processing"
            >Generar</v-btn
          >
          <v-btn
            depressed
            color="info"
            text
            @click="generarVistaPreviaNotaCredito"
            :disabled="processing"
            >Vista Previa</v-btn
          >
          <v-btn depressed text color="error" @click="cancelDownload"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog4" persistent :max-width="maxDialogWidth2">
      <v-card justify-center>
        <v-card-title>
          <span>Vista Previa Factura</span>
        </v-card-title>
        <v-card-text>
          <!-- <div grid-list-md> -->
          <v-form lazy-validation v-if="dialog4">
            <iframe
              style="border: none"
              id="frameID"
              width="100%"
              height="500px"
              :src="urlVistaPrevia"
              allowfullscreen
            ></iframe>
          </v-form>
          <v-row row v-if="processing2">
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </v-row>
          <!-- </div> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
            depressed
            color="primary"
            @click="generarYSubirFactura"
            :disabled="processing2"
            >Guardar y Cargar</v-btn
          >
          <v-btn depressed text color="primary" @click="cancelDownload"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog5" persistent :max-width="maxDialogWidth2">
      <v-card justify-center>
        <v-card-title>
          <span>Vista Previa Nota Credito</span>
        </v-card-title>
        <v-card-text>
          <!-- <div grid-list-md> -->
          <v-form lazy-validation v-if="dialog5">
            <iframe
              style="border: none"
              id="frameID"
              width="100%"
              height="500px"
              :src="urlVistaPrevia"
              allowfullscreen
            ></iframe>
          </v-form>
          <v-row row v-if="processing2">
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </v-row>
          <!-- </div> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
            depressed
            color="primary"
            @click="generarYSubirNotaCredito"
            :disabled="processing2"
            >Guardar y Cargar</v-btn
          >
          <v-btn depressed text color="primary" @click="cancelDownload"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import facturaAPI from "@/api/facturas";
import reporteAPI from "@/api/reportes";
import download from "downloadjs";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  data: () => {
    return {
      item: {
        id: null,
        tipo: "",
        sociedad: "",
        ncf: "",
        file: [],
        fechaEmision: null,
        fechaVencimiento: null,
      },
      drawerRight: true,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      selectedEstado: "",
      ncfFacturaMask: "",
      ncfNotaCreditoMask: "",
      listFacturas: [],
      listNotasCreditos: [],
      progressFactura: false,
      progressNotaCredito: false,
      paginationMaster: {
        sortBy: "FechaProcesado",
        descending: true,
      },
      headers: [
        { text: "", value: "Bloqueada" },
        { text: "CORTE", value: "Corte" },
        { text: "SOCIEDAD", value: "Sociedad" },
        { text: "FECHA GENERACION", value: "FechaProcesado" },
        { text: "FECHA RECIBIDO", value: "FechaRecibido" },
        { text: "HORA RECIBIDO", value: "HoraRecibido" },
        { text: "NCF", value: "NCF" },
        { text: "ESTADO", value: "EstadoCode" },
        { text: "", value: "action", sortable: false },
      ],
      listEstados: [
        { code: "A", value: "Aprobado" },
        { code: "D", value: "Devuelto" },
        { code: "P", value: "Pendiente" },
        { code: "R", value: "Recibido" },
      ],
      processing: false,
      processing2: false,
      urlVistaPrevia: "",
      rules: {
        ncfShowMessage: false,
        ncfValidationMessage: "",
        notNull: [(v) => !!v || "Este campo es requerido"],
        fechaEmision: [
          (v) => !!v || "Este campo es requerido",
          (v) =>
            moment(v).diff(moment(), "days") <= 60 ||
            "La fecha no debe exceder los 60 días",
        ],
        facturasRCN: [(v) => !!v || "Este campo es requerido"],
        notasCreditoRCN: [(v) => !!v || "Este campo es requerido"],
        fileRules: [
          (v) =>
            (v && v.size < 4 * 1000 * 1000) ||
            "El archivo que esta intentando subir excede los 4 MB",
        ],
      },
      breadcrumb: [
        {
          text: "Facturas",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    errormessage() {
      return this.$store.getters.errormessage;
    },
    listaCortes() {
      return this.$store.getters.cortes;
    },
    showMasterButton() {
      return this.resultMaster.length > 1;
    },
    showFooterDetail() {
      return (
        this.paginationDetail.totalItems > this.paginationDetail.rowsPerPage
      );
    },
    formMasivaIsValid() {
      return this.fileNombre !== "" && this.fileNombre !== null;
    },
    filteredFacturas() {
      return this.listFacturas.filter((i) => {
        return !this.selectedEstado || i.EstadoCode === this.selectedEstado;
      });
    },
    filteredNotasCredito() {
      return this.listNotasCreditos.filter((i) => {
        return !this.selectedEstado || i.EstadoCode === this.selectedEstado;
      });
    },
    maxDialogWidth() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "25%";
      } else {
        return "100%";
      }
    },
    maxDialogWidth2() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "40%";
      } else {
        return "100%";
      }
    },
    resize() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "";
      } else {
        return "width: 100%";
      }
    },
  },
  methods: {
    ...mapActions(["setMessage"]),
    set(item) {
      this.item.id = item.Id;
    },
    getFacturas() {
      this.progressFactura = true;
      this.listFacturas = [];
      facturaAPI
        .getFactura()
        .then((response) => {
          this.listFacturas = response.data;
          this.progressFactura = false;
        })
        .catch((error) => {
          this.progressFactura = false;
          this.$store.dispatch("setError", true);
          this.$store.dispatch("setErrorMessage", error.message);
        });
    },
    upload(item, tipo) {
      this.item.id = item.Id;
      this.item.tipo = tipo;
      this.item.ncf = item.NCFPredigitado;
      this.item.sociedad = item.Sociedad;
      this.dialog1 = true;
    },
    getNotasCreditos() {
      this.progressNotaCredito = true;
      this.listNotasCreditos = [];
      facturaAPI
        .getNotasCreditos()
        .then((response) => {
          this.listNotasCreditos = response.data;
          this.progressNotaCredito = false;
        })
        .catch((error) => {
          this.progressNotaCredito = false;
          this.$store.dispatch("setError", true);
          this.$store.dispatch("setErrorMessage", error.message);
        });
    },
    getNCFMask() {
      this.listMask = [];

      facturaAPI
        .getNCFMask()
        .then((response) => {
          this.listMask = response.data;

          var newRegexFacturas = new RegExp(response.data[2]);
          var newRegexCredito = new RegExp(response.data[3]);

          this.ncfFacturaMask = response.data[0];
          this.ncfNotaCreditoMask = response.data[1];

          this.rules = {
            ...this.rules,
            facturasRCN: [
              (v) => !!v || "Este campo es requerido",
              (v) =>
                response.data[0].length === 0 ||
                (v && v.length === response.data[0].length) ||
                "El formato del NCF no es válido",
              (v) =>
                response.data[0].length === 0 ||
                newRegexFacturas.test(v) ||
                "El formato del NCF no es válido",
            ],
            notasCreditoRCN: [
              (v) => !!v || "Este campo es requerido",
              (v) =>
                response.data[1].length === 0 ||
                (v && v.length === response.data[1].length) ||
                "El formato del NCF no es válido",
              (v) =>
                response.data[1].length === 0 ||
                newRegexCredito.test(v) ||
                "El formato del NCF no es válido",
            ],
          };
        })
        .catch((error) => {
          this.$store.dispatch("setError", true);
          this.$store.dispatch("setErrorMessage", error.message);
        });
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
    cancelInsert() {
      this.dialog1 = false;
      this.rules.ncfShowMessage = false;
      this.rules.ncfValidationMessage = "";
      this.setFechasDefault();
      this.item.file = [];
    },
    cancelDownload() {
      this.processing = false;
      this.processing2 = false;
      this.dialog3 = false;
      this.dialog2 = false;
      this.dialog4 = false;
      this.dialog5 = false;
      this.rules.ncfShowMessage = false;
      this.rules.ncfValidationMessage = "";
      this.setFechasDefault();
      this.file = [];
    },
    submit() {
      this.rules.ncfShowMessage = false;
      this.rules.ncfValidationMessage = "";
      if (this.$refs.form.validate()) {
        this.loader = "loading";
        let data = new FormData();
        data.append("AsociadoId", this.user.asociado.id);
        data.append("Archivos", this.item.file);
        data.append("NCF", this.item.ncf);
        data.append("ReporteAsociadoId", this.item.id);
        data.append("Tipo", this.item.tipo);
        this.loading = true;
        facturaAPI
          .cargaFactura(data)
          .then((response) => {
            this.loading = false;
            this.setFechasDefault();
            if (response.data !== "success") {
              this.rules.ncfShowMessage = true;
              this.rules.ncfValidationMessage = response.data;
            } else {
              this.getFacturas();
              this.getNotasCreditos();
              this.cancelInsert();
              let mensaje = {
                title: "Satisfactorio",
                body: "Archivo cargado satisfactoriamente",
                type: "success",
              };
              this.setMessage(mensaje);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$store.dispatch("setError", true);
            this.$store.dispatch("setErrorMessage", error.message);
          });
      }
    },
    generarFactura() {
      this.rules.ncfShowMessage = false;
      this.rules.ncfValidationMessage = "";
      if (this.$refs.formFacturaDescarga.validate()) {
        this.processing = true;
        facturaAPI
          .validarNCFFacturaNotaCredito(this.item.ncf)
          .then((response) => {
            if (response.data === "Ok") {
              var data = JSON.stringify({
                CanalAsociadoId: this.user.asociado.canalAsociadoId,
                FacturaId: this.item.id,
                NCF: this.item.ncf,
                FechaEmision: this.item.fechaEmision,
                FechaVencimiento: this.item.fechaVencimiento,
                Tipo: "Factura",
                ReporteAsociadoId: this.item.id,
              });
              reporteAPI
                .GenerarFacturaNotaCredito(data)
                .then((response) => {
                  self.isExporting = false;
                  var factura = this.listFacturas.find(
                    (c) => c.Id === this.item.id
                  );
                  var nombreArchivo =
                    this.$store.getters.user.asociado.codigo +
                    "_" +
                    factura.Corte +
                    "_Factura.pdf";
                  download(response.data, nombreArchivo);
                  this.cancelDownload();
                  this.getFacturas();
                  this.processing = false;
                })
                .catch((error) => {
                  this.$store.dispatch("setErrorMessage", error.message);
                  this.processing = false;
                });
            } else {
              this.rules.ncfShowMessage = true;
              this.rules.ncfValidationMessage = response.data;
              this.processing = false;
            }
          })
          .catch((error) => {
            this.$store.dispatch("setErrorMessage", error.message);
            this.processing = false;
          });
      }
    },
    generarVistaPreviaFactura() {
      this.rules.ncfShowMessage = false;
      this.rules.ncfValidationMessage = "";
      if (this.$refs.formFacturaDescarga.validate()) {
        this.processing = true;
        facturaAPI
          .validarNCFFacturaNotaCredito(this.item.ncf)
          .then((response) => {
            if (response.data === "Ok") {
              var data = JSON.stringify({
                CanalAsociadoId: this.user.asociado.canalAsociadoId,
                FacturaId: this.item.id,
                NCF: this.item.ncf,
                FechaEmision: this.item.fechaEmision,
                FechaVencimiento: this.item.fechaVencimiento,
                Tipo: "Factura",
                ReporteAsociadoId: this.item.id,
              });
              reporteAPI
                .GenerarFacturaNotaCredito(data)
                .then((response) => {
                  this.dialog4 = true;
                  self.isExporting = false;
                  var file = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  var fileURL = URL.createObjectURL(file);
                  this.urlVistaPrevia = fileURL;
                })
                .catch((error) => {
                  this.$store.dispatch("setErrorMessage", error.message);
                  this.processing = false;
                });
            } else {
              this.rules.ncfShowMessage = true;
              this.rules.ncfValidationMessage = response.data;
              this.processing = false;
            }
          })
          .catch((error) => {
            this.$store.dispatch("setErrorMessage", error.message);
            this.processing = false;
          });
      }
    },
    generarNotaCredito() {
      this.rules.ncfShowMessage = false;
      this.rules.ncfValidationMessage = "";
      if (this.$refs.formNotaCreditoDescarga.validate()) {
        this.processing = true;
        this.processing = true;
        facturaAPI
          .validarNCFFacturaNotaCredito(this.item.ncf, "Factura")
          .then((response) => {
            if (response.data === "Ok") {
              var data = JSON.stringify({
                CanalAsociadoId: this.user.asociado.canalAsociadoId,
                FacturaId: this.item.id,
                NCF: this.item.ncf,
                FechaEmision: this.item.fechaEmision,
                Tipo: "NotaCredito",
                ReporteAsociadoId: this.item.id,
              });
              reporteAPI
                .GenerarFacturaNotaCredito(data)
                .then((response) => {
                  self.isExporting = false;
                  var factura = this.listFacturas.find(
                    (c) => c.Id === this.item.id
                  );
                  var nombreArchivo =
                    this.$store.getters.user.asociado.codigo +
                    "_" +
                    factura.Corte +
                    "_NotaCredito.pdf";
                  download(response.data, nombreArchivo);
                  this.cancelDownload();
                  this.getNotasCreditos();
                  this.processing = false;
                })
                .catch((error) => {
                  this.$store.dispatch("setError", true);
                  this.$store.dispatch("setErrorMessage", error.message);
                  this.processing = false;
                });
            } else {
              this.rules.ncfShowMessage = true;
              this.rules.ncfValidationMessage = response.data;
              this.processing = false;
            }
          })
          .catch((error) => {
            this.$store.dispatch("setErrorMessage", error.message);
            this.processing = false;
          });
      }
    },
    generarVistaPreviaNotaCredito() {
      this.rules.ncfShowMessage = false;
      this.rules.ncfValidationMessage = "";
      if (this.$refs.formNotaCreditoDescarga.validate()) {
        this.processing = true;
        this.processing = true;
        facturaAPI
          .validarNCFFacturaNotaCredito(this.item.ncf, "Factura")
          .then((response) => {
            if (response.data === "Ok") {
              var data = JSON.stringify({
                CanalAsociadoId: this.user.asociado.canalAsociadoId,
                FacturaId: this.item.id,
                NCF: this.item.ncf,
                FechaEmision: this.item.fechaEmision,
                Tipo: "NotaCredito",
                ReporteAsociadoId: this.item.id,
              });
              reporteAPI
                .GenerarFacturaNotaCredito(data)
                .then((response) => {
                  this.dialog5 = true;
                  self.isExporting = false;
                  var file = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  var fileURL = URL.createObjectURL(file);
                  this.urlVistaPrevia = fileURL;
                })
                .catch((error) => {
                  this.$store.dispatch("setError", true);
                  this.$store.dispatch("setErrorMessage", error.message);
                  this.processing = false;
                });
            } else {
              this.rules.ncfShowMessage = true;
              this.rules.ncfValidationMessage = response.data;
              this.processing = false;
            }
          })
          .catch((error) => {
            this.$store.dispatch("setErrorMessage", error.message);
            this.processing = false;
          });
      }
    },
    generarYSubirFactura() {
      this.processing2 = true;
      facturaAPI
        .validarNCFFacturaNotaCredito(this.item.ncf)
        .then((response) => {
          if (response.data === "Ok") {
            var data = JSON.stringify({
              CanalAsociadoId: this.user.asociado.canalAsociadoId,
              FacturaId: this.item.id,
              NCF: this.item.ncf,
              FechaEmision: this.item.fechaEmision,
              FechaVencimiento: this.item.fechaVencimiento,
              Tipo: "Factura",
              ReporteAsociadoId: this.item.id,
              AsociadoId: this.user.asociado.id,
            });
            reporteAPI
              .GenerarYSubirFacturaNotaCredito(data)
              .then(() => {
                this.cancelDownload();
                this.getFacturas();
                this.processing2 = false;
              })
              .catch((error) => {
                this.$store.dispatch("setErrorMessage", error.message);
                this.processing2 = false;
              });
          } else {
            this.rules.ncfShowMessage = true;
            this.rules.ncfValidationMessage = response.data;
            this.processing2 = false;
          }
        })
        .catch((error) => {
          this.$store.dispatch("setErrorMessage", error.message);
          this.processing2 = false;
        });
    },
    generarYSubirNotaCredito() {
      this.processing2 = true;
      facturaAPI
        .validarNCFFacturaNotaCredito(this.item.ncf, "Factura")
        .then((response) => {
          if (response.data === "Ok") {
            var data = JSON.stringify({
              CanalAsociadoId: this.user.asociado.canalAsociadoId,
              FacturaId: this.item.id,
              NCF: this.item.ncf,
              FechaEmision: this.item.fechaEmision,
              Tipo: "NotaCredito",
              ReporteAsociadoId: this.item.id,
              AsociadoId: this.user.asociado.id,
            });
            reporteAPI
              .GenerarYSubirFacturaNotaCredito(data)
              .then(() => {
                this.cancelDownload();
                this.getNotasCreditos();
                this.processing = false;
              })
              .catch((error) => {
                this.$store.dispatch("setError", true);
                this.$store.dispatch("setErrorMessage", error.message);
                this.processing2 = false;
              });
          } else {
            this.processing2 = false;
          }
        })
        .catch((error) => {
          this.$store.dispatch("setErrorMessage", error.message);
          this.processing2 = false;
        });
    },
    download(id, tipo) {
      facturaAPI
        .DescargaFacturaNotaCredito(id, tipo)
        .then((response) => {
          self.isExporting = false;
          var factura;
          if (tipo === "Factura") {
            factura = this.listFacturas.find((c) => c.Id === id);
          } else {
            factura = this.listNotasCreditos.find((c) => c.Id === id);
          }
          var nombreArchivo =
            this.user.asociado.codigo +
            "_" +
            factura.Corte +
            "_" +
            tipo +
            response.statusText;
          download(response.data, nombreArchivo);
          this.cancelDownload();
          this.processing = false;
        })
        .catch((error) => {
          this.$store.dispatch("setError", true);
          this.$store.dispatch("setErrorMessage", error.message);
          this.processing = false;
        });
      this.setFechasDefault();
    },
    setFechasDefault() {
      this.item.ncf = "";
      this.item.fechaEmision = moment().format("YYYY-MM-DD"); // Fecha Emision default segun comisiones
      this.item.fechaVencimiento = moment(
        new Date(new Date().getFullYear(), 11, 31)
      ).format("YYYY-MM-DD"); // Fecha Vencimiento default segun comisiones
    },
  },
  mounted() {
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    if (this.user.asociado.canalAsociadoId) {
      this.getFacturas();
      this.getNCFMask();
      this.getNotasCreditos();
      this.setFechasDefault();
      this.setFechasDefault();
    } else {
      // setTimeout(() => this.getFacturas(), 2000)
      // setTimeout(() => this.getNotasCreditos(), 2000)
    }
  },
};
</script>
<style>
.v-input__prepend-outer {
  display: none;
}
</style>